import request from "@/utils/request";

// 成果库列表
export function list(data) {
  return request({
    url: "/resources/queryAchieveList",
    method: "post",
    data,
  });
}

// 成果库详情
export function view(data) {
  return request({
    url: "/resources/queryAchieveDetail",
    method: "post",
    data
  });
}
